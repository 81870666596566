/* eslint-disable @typescript-eslint/no-unused-vars */
import { IObject } from "interfaces/storyline";
import { IAction, IClientCharacter } from 'interfaces/storyline';
import { store } from "../store/store"
import actionConsts from "../store/constants"
import kfetch from './kfetch';


export const getString = (key: string | null, defaultValue = "", params?: any): string => {
    const { storylineData } = store.getState();
    let labels = storylineData.stringLookup;
    let label = defaultValue;
    labels = { ...labels, ...params }

    if (!key) {
        label = defaultValue ? defaultValue : label;
        return label;
    }

    if (labels !== undefined && labels[key] !== undefined) {
        label = labels[key];
    }

    if (key === null || key === undefined) {
        label = defaultValue;
    }

    const re = new RegExp(`<%=*[\\sa-z{A-Z}{0-9}_\\s]*%>`, "g");
    let extraLabel: RegExpExecArray | null;
    do {
        extraLabel = re.exec(label);
        re.lastIndex = 0; // Reset

        if (extraLabel) {
            const lkey = extraLabel[0].replace(/[<%=>]/g, "");
            label = label.replace(extraLabel[0], labels[lkey.trim()]);
        }
    } while (extraLabel);

    // const re2 = new RegExp(`<%=\s*[a-zA-D_]*%>`, "g");
    // let extraActor: RegExpExecArray | null;
    // do {
    //     extraActor = re.exec(label);
    //     re.lastIndex = 0; // Reset

    //     if (extraActor) {
    //         const lkey = extraActor[0].replaceAll(/[<%=>]/g, "");
    //         label = label.replaceAll(extraActor[0], labels[lkey]);
    //     }
    // } while (extraActor);

    if (!label || label == "") return key;

    return label;
};

/* Adding classes to a targetID element */
export const addClassToElement = (
    targetID: string,
    ...addClassNames: string[]
) : void => {
    const targetElement = document.getElementById(targetID);
    if (targetElement) targetElement.classList.add(...addClassNames);
};

/* Removing classes from a targetID element */
export const removeClassFromElement = (
    targetID: string,
    ...removeClassNames: string[]
) : void => {
    const targetElement = document.getElementById(targetID);
    if (targetElement) targetElement.classList.remove(...removeClassNames);
};

/* Returns month number */
export const getMonth = (day: string, interval: number): number => {
    return Math.floor(parseInt(day) / interval);
};

export const convertToMap = (data: any[], key: string): IObject => {
    const hash: IObject = {}
    data.map((item) => {
        if (item[key] != null) {
            hash[item[key]] = item
        }
    })
    return hash
}

export const limitTrustToValidRange = (trust: number): number => {
    if (trust > 100) {
        return 100
    }
    else if (trust < 0) {
        return 0
    }
    else {
        return trust
    }
}

/*
    Computes overall trust of client characters and also can update it to global redux state 
    if clientchars not passed as argument
*/
export const computeOverallTrust = (clientChars ?:any) => {
    let clientCharactersData = []
    if(!clientChars){
        clientCharactersData = store.getState().storylineData.clientCharacters
    }else{
        clientCharactersData = clientChars
    }
    const oldTrust = store.getState().globalData.overallTrust
    const oldTrustComp = store.getState().globalData.overallTrustComp

    let overallTrust = 0,
        teamSize = 0,
        overallTrustComp = 0,
        currentWeight = 0,
        totalWeight = 0;

    clientCharactersData && clientCharactersData.forEach((charData: IClientCharacter) => {
        const team = charData['ch_type']
        if (team !== 0) {
            currentWeight = charData['chr_weight']
            totalWeight += currentWeight
            overallTrust += charData['gc_trust'] * currentWeight
            overallTrustComp += charData['gc_trust_comp'] * currentWeight
            teamSize++
        }
    })

    overallTrust = overallTrust / totalWeight
    overallTrustComp = overallTrustComp / totalWeight

    overallTrust = limitTrustToValidRange(overallTrust)
    overallTrustComp = limitTrustToValidRange(overallTrustComp)

    let deltaTrust = -1, deltaTrustComp = -1
    if (oldTrust !== -1) {
        deltaTrust = overallTrust - oldTrust
        deltaTrustComp = overallTrustComp - oldTrustComp
    }

    if(!clientChars)
        store.dispatch({
            type: actionConsts.UPDATE_GLOBAL_DATA,
            payload: { overallTrust: overallTrust, overallTrustComp: overallTrustComp, deltaOverallTrust: deltaTrust, deltaOverallTrustComp: deltaTrustComp }
        })

    return { overallTrust, overallTrustComp, deltaTrust, deltaTrustComp }
}

/*
    Function to put restrictions on Take Action button in Action Description 
    popup.
    minStakeHolder --> Min. client chars to be selected. 
    maxStakeHolder --> Max. client chars that can be selected. 
    isTeamMemberSelectRequired --> is it necessary to select team member for take 
        action button to be enabled. 
    Remember that when client character popup is opened, the character whose popup 
    is opened is by default selected as stakeholder for the action if taken. 

*/
export const actionValidationCheck = (actionData: IAction) => {

    switch (actionData['sta_act_id']) {
        //Background info
        case 1: {
            return {
                minStakeholder: 1,
                maxStakeholder: 1,
                isTeamMemberSelectRequired: false
            }
        }
        //Meeting
        case 2: {
            return {
                minStakeholder: 1,
                maxStakeholder: 99,
                isTeamMemberSelectRequired: true
            }
        }
        //Email
        case 3: {
            return {
                minStakeholder: 1,
                maxStakeholder: 99,
                isTeamMemberSelectRequired: true
            }
        }
        //Proposal
        case 4: {
            return {
                minStakeholder: 1,
                maxStakeholder: 99,
                isTeamMemberSelectRequired: true
            }
        }
        //Lobby
        case 5: {
            return {
                minStakeholder: 2,
                maxStakeholder: 2,
                isTeamMemberSelectRequired: false,
            }
        }
        //Seek Advice
        case 7: {
            return {
                minStakeholder: 1,
                maxStakeholder: 1,
                isTeamMemberSelectRequired: false
            }
        }
        // Invite workshop
        case 9: {
            return {
                minStakeholder: 1,
                maxStakeholder: 1,
                isTeamMemberSelectRequired: false
            }
        }
        // Presentation
        case 10: {
            return {
                minStakeholder: 1,
                maxStakeholder: 99,
                isTeamMemberSelectRequired: true
            }
        }
        // Uncover network
        case 11: {
            return {
                minStakeholder: 1,
                maxStakeholder: 1,
                isTeamMemberSelectRequired: false
            }
        }
        //Buyer role 
        case 12: {
            return {
                minStakeholder: 1,
                maxStakeholder: 1,
                isTeamMemberSelectRequired: false
            }
        }
        // Build rapport
        case 13: {
            return {
                minStakeholder: 1,
                maxStakeholder: 1,
                isTeamMemberSelectRequired: true
            }
        }
        default: {
            return {
                minStakeholder: 1,
                maxStakeholder: 99,
                isTeamMemberSelectRequired: true
            }
        }
    }
}

/*
    Returns directly impacted and indirectly impacted actor list in the action response
    if(ghd_influence == 1) --> indirectly impacted actor
    else directly impacted actor
*/
export const getAffectedCharList = (historyData: any, allCharacterList: any) => {

    const userHistory = historyData.user_game_history_details
    const clientCharactersData = store.getState().storylineData.clientCharacters

    const directlyImpactedData: any = []
    const indirectlyImpactedData: any = []

    for (let i = 0; i < userHistory.length; i++) {

        const charData = clientCharactersData.find((char: IClientCharacter) => {
            return char['stc_id'] === userHistory[i]['ghd_stc_id']
        })

        //check if any networks revealed for character.
        const networkCharName = getNetworkCharName(userHistory[i], allCharacterList)

        const resData = {
            responseDesc: userHistory[i]['ghd_detail'],
            charName: charData['ch_name'],
            charImage: charData['ch_image'],
            deltaTrust: userHistory[i]['ghd_delta'],
            deltaTrustComp: userHistory[i]['ghd_comp_delta'],
            updatedTrust: charData['gc_trust'],
            updatedTrustComp: charData['gc_trust_comp'],
            networkCharName: networkCharName
        }

        if (userHistory[i]['ghd_influence'] === 1) {
            indirectlyImpactedData.push(resData)
        }
        else if (userHistory[i]['ghd_influence'] === 0) {
            directlyImpactedData.push(resData)
        }
    }

    return { directlyImpactedData, indirectlyImpactedData }
}


/*
    Input: historyDetail of character. (user_game_history_details[i]), allCharacterList: hashmap of all characters
    Output: Empty string if no char revealed, Else label of character name revealed (will be required to decode using getString())
*/
export const getNetworkCharName = (charHistoryDetails: any, allCharacterList: any): string => {

    let networkCharName = ''
    const char_network = charHistoryDetails.character_network

    if (char_network) {
        const networkSrcCharId = char_network.ln_src
        const networkTarCharId = char_network.ln_tar_id

        let networkChar;
        if (networkSrcCharId === charHistoryDetails['ghd_stc_id']) {
            networkChar = allCharacterList[networkTarCharId]
        }
        else {
            networkChar = allCharacterList[networkSrcCharId]
        }

        networkCharName = networkChar['ch_name']
    }

    return networkCharName
}

/*
    Fetch option details for action with You team member selected
*/
export const getActionOptions = async (actId: number): Promise<any> => {
    const response = await kfetch(`${process.env.REACT_APP_BACKEND_URL}/actions/${actId}/options`)
        .then((res) => {
            return res
        })
    return response

}


export const getEventResultData = async (eventParams: any): Promise<any> => {
    const response = await kfetch(`${process.env.REACT_APP_BACKEND_URL}/history/${eventParams['gh_id']}`, "PUT", eventParams)
        .then((res) => {
            return res
        })
    return response
}
