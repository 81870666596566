import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "config/hooks";
import { bindActionCreators } from "redux"
import * as actionCreators from "store/actionCreator"
import { HashRouter, Route, Switch, RouteComponentProps } from 'react-router-dom'
import routes from './routes/routes'

import KnolskapeLoader from 'components/commonComponents/loaders/KnolskapeLoader'
import { computeOverallTrust, getString } from 'utils'


//preloading images
// import background from "assets/images/intro/BG_intro.svg"
// import landing from "assets/images/intro/landing.svg"
// import qoute from "assets/images/intro/qoute.svg"
// import introIllustration from "assets/images/intro/illustration_introduction.svg"
// import objectivesIllustration from "assets/images/intro/illustration_objectives.svg"
// import illustrationTeam from 'assets/images/intro/illustration_meet_your_team.svg'


const App: React.FC = () => {
  // redux state, action, dispatchers

  const dispatch = useAppDispatch();
  const { setStorylineData, setUser, setHistory, setUserMap, setMonth, setShowWalkthrough, resetGlobalData, getPowermapLocations } = bindActionCreators(actionCreators, dispatch)
  const isDataFetched = useAppSelector(state => state.isDataFetched)
  const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)
  const userTimer = useAppSelector(state => state.initUser.userTimer)
  const gameStatus = useAppSelector(state => state.initUser.status)

  let teamCharacters = useAppSelector(state => state.storylineData.teamCharacters)
  teamCharacters = !teamCharacters ? [] : teamCharacters
  const addYouTeamCharacter = () => {
    // add You team char
    teamCharacters.length == 3 ? teamCharacters.push({
      stc_id: '#',
      ch_name: getString("label_base_you"),
      ch_image: getString("img_you"),
    }) : null
  }

  useEffect(() => {
    console.log("isDataFetched value", isDataFetched)
    if (isDataFetched > 6 || isDataFetched == 5) {
      // globalData should be reseted at first to reset data set by demo user in state. 
      resetGlobalData()
      setMonth()
      computeOverallTrust()
      setShowWalkthrough(userCurrentDay, userTimer, gameStatus)
      addYouTeamCharacter()
    }
    else if (isDataFetched == 6 ) {
      console.log("inside else if isDataFetched 6")
      setMonth()
      computeOverallTrust()
      addYouTeamCharacter()
      setShowWalkthrough(userCurrentDay, userTimer, gameStatus)
    }
    else if (isDataFetched == 1) {
      setStorylineData()
      setHistory()
      setUserMap()
      getPowermapLocations()
    }
    else if (isDataFetched == 0) {
      setUser()
    }
  }, [addYouTeamCharacter, gameStatus, getPowermapLocations, isDataFetched, resetGlobalData, setHistory, setMonth, setShowWalkthrough, setStorylineData, setUser, setUserMap, userCurrentDay, userTimer])

  return (
    <>
      <HashRouter>
        {isDataFetched >= 5 ?
          <Switch>

            {
              routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={(props: RouteComponentProps<any>) => (
                      <route.component
                        name={route.name}
                        {...props}
                        {...route.props}
                      />
                    )}
                  />
                )
              })
            }
          </Switch>
          : <KnolskapeLoader />
        }
      </HashRouter>

      {/* Preloading images */}
      {/* <div style={{ display: 'none'}}>
        
      </div> */}
    </>
  )
}

declare global {
  interface Window {
    kWidget?: any
    feedback?: any
  }
}

export default App;
